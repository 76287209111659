<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Timesheets">
			<b-col cols="12" xl="12">
				<h1>{{ this.caption2 }}</h1>
				<b-card no-body>
					<b-card-body>
						<b-row>
							<b-col sm="6"></b-col>
							<b-col sm="6">
								<b-form-group>
									<b-button :block="!$screen.md" @click="goBack()" variant="success" size="md" class="float-right"
										>{{ this.FormMSG(12, 'Back to salaries for one department') }} <i class="icon-action-undo"></i
									></b-button>
								</b-form-group>
							</b-col>
						</b-row>
						<div class="clearfix mb-4">
							<p>{{ this.FormMSG(11, 'Click a timesheet to see the details. Timesheets are divided by week from Monday to Sunday.') }}</p>
						</div>
						<!--     TABLE OF Timesheets     sort-by="date"    use striped to highlight each even row  -->
						<CardListBuilder v-if="$screen.width < 992" :items="Timesheets" :fields="tsfields">
							<template slot="actions" slot-scope="data">
								<b-button variant="primary" size="sm" @click="rowClicked(data.item)">
									<i class="icon-pencil"></i>
								</b-button>
								<b-button
									variant="danger"
									v-if="data.item.validated == 0 || data.item.validated == 4 || data.item.validated == 16"
									size="sm"
									@click="delItem(data.item.id)"
									><i class="fa fa-times" aria-hidden="true"></i
								></b-button>
							</template>
						</CardListBuilder>
						<b-table
							v-if="$screen.width >= 992"
							:hover="hover"
							responsive="sm"
							:items="Timesheets"
							:fields="tsfields"
							:current-page="currentPage"
							:per-page="perPage"
							sticky-header="700px"
							@row-clicked="rowClicked"
							:head-variant="hv"
							bordered
							small
						>
							<!-- <template slot="rem" slot-scope="data"> -->
							<template v-slot:cell(rem)="data">
								<b-button
									variant="danger"
									v-if="data.item.validated == 0 || data.item.validated == 4 || data.item.validated == 16"
									size="sm"
									@click="delItem(data.item.id)"
								>
									X
								</b-button>
							</template>
							<template v-slot:cell(validatedStatus)="data">
								<!-- <span v-bind:class="['badge badge-' + data.item.validatedClass]">{{ data.item.validatedStatus }}</span> -->
								<div class="wrap-status d-flex">
									<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
										{{ data.item.validatedStatus }}
									</div>
								</div>
							</template>
						</b-table>
						<!-- <loading :active.sync="isLoading" :is-full-page="true"></loading> -->
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import { classStatus } from '@/shared/global-status';
import globalMixin from '@/mixins/global.mixin';

const query_timesheets = gql`
	query ($UserId: Int!) {
		GetTimesheetsForUser(UserId: $UserId) {
			id
			strDate
			endDate
			hours
			minutes
			hoursOvertime
			minutesOvertime
			hoursNight
			minutesNight
			hoursTooEarly
			minutesTooEarly
			salary
			validated
			comment
			user {
				name
				firstName
			}
			hoursOvertime1
			hoursOvertime2
			minutesOvertime1
			minutesOvertime2
			hoursTransportTimePaid
			minutesTransportTimePaid
		}
	}
`;

export default {
	name: 'TimesheetsOfUser',
	mixins: [languageMessages, globalMixin],
	props: {
		caption1: {
			type: String,
			default: 'Timesheets'
		},
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			Timesheets: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			isLoading: false,
			curTs: {
				id: 0,
				strDate: '',
				endDate: '',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				validated: 0,
				validatedStatus: 'Not Submited'
			}
		};
	},
	created() {
		if (this.curTs.strDate.length == 0) {
			this.firstTimeInitialisation();
		}
		this.reloadData();
	},
	computed: {
		caption2: function () {
			return this.Timesheets.length > 0
				? this.FormMSG(1, 'Timesheet details for:') + ' ' + this.Timesheets[0].user.name + ' ' + this.Timesheets[0].user.firstName
				: this.FormMSG(1, 'Timesheet details for:');
		},
		tsfields() {
			return [
				{
					key: 'validatedStatus',
					label: this.FormMSG(20, 'Status'),
					sortable: true
				},
				{
					key: 'strDate',
					label: this.FormMSG(21, 'Start'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7);
					},
					sortable: true
				},
				{
					key: 'endDate',
					label: this.FormMSG(22, 'End'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7);
					},
					sortable: false
				},
				{
					key: 'hhmm',
					label: this.FormMSG(23, 'Hours'),
					sortable: false
				},
				{
					key: 'hhmmOvt',
					label: this.FormMSG(24, 'Overtime'),
					sortable: false
				},
				{
					key: 'hhmmOvt1',
					label: this.FormMSG(240, 'Overtime 1'),
					sortable: false
				},
				{
					key: 'hhmmOvt2',
					label: this.FormMSG(241, 'Overtime 2'),
					sortable: false
				},
				{
					key: 'hhmmTransport',
					label: this.FormMSG(242, 'Transport'),
					sortable: false
				},
				{
					key: 'hhmmNight',
					label: this.FormMSG(25, 'Night'),
					sortable: false
				},
				{
					key: 'hhmmTooEarly',
					label: this.FormMSG(26, 'Anticipated'),
					sortable: false
				},
				{
					key: 'salary',
					label: this.FormMSG(27, 'salary'),
					formatter: (value) => {
						return this.displayCurrency(value);
						// return value + ' ' + this.getCurrency();
					},
					sortable: false
				}
			];
		},
		styleObject: function () {
			var myObj = {
				color: 'white',
				fontSize: '16px'
			};
			if (this.curTs.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curTs.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curTs.validated == 8) {
				myObj.color = 'green';
			} else if (this.curTs.validated == 4 || this.curTs.validated == 16) {
				myObj.color = 'red';
			} else if (this.curTs.validated == 0) {
				myObj.color = 'red';
			}
			return myObj;
		}
	},
	methods: {
		displayCurrency(value) {
			if (isNil(value)) {
				return '0';
			}

			//console.log("in displayCurrency",value);
			return this.getCurrency() + ' ' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
		},
		getCurrency() {
			return store.state.myProfile.currencySymbol;
		},
		selectCurTs() {
			// check if curTs is in array of existing timesheet.
			// if yes, select it, if not, initialise a new curTs with defaultvalues
			// look if chosen date is in timesheet, if yes get structure
			var foundCurTsInArray = false;
			//console.log("in selectCurTs");
			for (var i = 0; i < this.Timesheets.length; i++) {
				var temp = this.Timesheets[i].strDate;
				temp = temp.substring(0, 10);
				console.log('temp:', temp);
				if (temp == this.curDay.strDate) {
					// fill curDay with tsTays Data
					this.curTs = this.Timesheets[i];
					//    console.log("in selectTs found line:", this.curTs.strDate)
					foundCurTsInArray = true;
					break;
				}
			}
			if (foundCurTsInArray == false) {
				if (this.Timesheets.length > 0) {
					this.curTs = this.Timesheets[0];
				} else {
					console.log('in selectCurTs not found in array');
					this.curTs.strDate = '2019-01-01';
				}
			}
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {},
		updateTZdataEach(timeData) {
			// setting row color
			timeData.validatedStatus = this.validatedText(timeData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			timeData._rowVariant = ''; //this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			//hh:mm
			timeData.hhmm = timeData.hours + ':' + timeData.minutes;
			timeData.hhmmOvt = timeData.hoursOvertime + ':' + timeData.minutesOvertime;
			timeData.hhmmNight = timeData.hoursNight + ':' + timeData.minutesNight;
			timeData.hhmmTooEarly = timeData.hoursTooEarly + ':' + timeData.minutesTooEarly;
			timeData.hhmmOvt1 = timeData.hoursOvertime1 + ':' + timeData.minutesOvertime1;
			timeData.hhmmOvt2 = timeData.hoursOvertime2 + ':' + timeData.minutesOvertime2;
			timeData.hhmmTransport = timeData.hoursTransportTimePaid + ':' + timeData.minutesTransportTimePaid;
			//console.log("timeData.hhmmTooEarly:",timeData.hhmmTooEarly);
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'info';
			}
			return retval;
		},
		reloadData() {
			this.isLoading = true;
			var StartDate = this.curMonthStr + '-01T00:00:00+00:00';
			var UserId = parseInt(this.$route.params.userId, 10);
			this.$apollo
				.query({
					query: query_timesheets,
					variables: {
						UserId
					},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					result.data.GetTimesheetsForUser.forEach(this.updateTZdataEach);
					this.Timesheets = result.data.GetTimesheetsForUser;
					//_.orderBy(Timesheets, ['strDate'], ['desc']);
					// console.log("reloaddata:", this.Timesheets);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				})
				.finally(() => (this.isLoading = false));
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		tsDetailLink(id) {
			//this.BudgetTotalOneDep = store.getCurBudgetDataForDepartment();
			//  return `/department/0/salaries/0/timesheet/${id.toString()}`
			return `/timesheet details/${id.toString()}`;
		},
		rowClicked(item) {
			// copy item content into curTs structure.
			store.setCurTimesheet(item);
			//console.log("item id:", item);
			const tsLink = this.tsDetailLink(item.id);
			//  console.log("rowclicked starting:",tsLink);
			this.$router.push({
				path: tsLink
			});
		},
		goBack() {
			this.$router.go(-1);
		},
		classStatus(validated) {
			return classStatus(validated);
		}
	}
};
</script>
