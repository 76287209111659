var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.Timesheets
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "12" } },
                [
                  _c("h1", [_vm._v(_vm._s(this.caption2))]),
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { sm: "6" } }),
                              _c(
                                "b-col",
                                { attrs: { sm: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "float-right",
                                          attrs: {
                                            block: !_vm.$screen.md,
                                            variant: "success",
                                            size: "md",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goBack()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.FormMSG(
                                                12,
                                                "Back to salaries for one department"
                                              )
                                            ) + " "
                                          ),
                                          _c("i", {
                                            staticClass: "icon-action-undo",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "clearfix mb-4" }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  this.FormMSG(
                                    11,
                                    "Click a timesheet to see the details. Timesheets are divided by week from Monday to Sunday."
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm.$screen.width < 992
                            ? _c("CardListBuilder", {
                                attrs: {
                                  items: _vm.Timesheets,
                                  fields: _vm.tsfields,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "actions",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "primary",
                                                size: "sm",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rowClicked(
                                                    data.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "icon-pencil",
                                              }),
                                            ]
                                          ),
                                          data.item.validated == 0 ||
                                          data.item.validated == 4 ||
                                          data.item.validated == 16
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "danger",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delItem(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-times",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3103606826
                                ),
                              })
                            : _vm._e(),
                          _vm.$screen.width >= 992
                            ? _c("b-table", {
                                attrs: {
                                  hover: _vm.hover,
                                  responsive: "sm",
                                  items: _vm.Timesheets,
                                  fields: _vm.tsfields,
                                  "current-page": _vm.currentPage,
                                  "per-page": _vm.perPage,
                                  "sticky-header": "700px",
                                  "head-variant": _vm.hv,
                                  bordered: "",
                                  small: "",
                                },
                                on: { "row-clicked": _vm.rowClicked },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(rem)",
                                      fn: function (data) {
                                        return [
                                          data.item.validated == 0 ||
                                          data.item.validated == 4 ||
                                          data.item.validated == 16
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "danger",
                                                    size: "sm",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delItem(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\tX\n\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(validatedStatus)",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "wrap-status d-flex",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class: `status ${_vm.classStatus(
                                                    data.item.validated
                                                  )}`,
                                                  staticStyle: {
                                                    "font-size": "0.7rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        data.item
                                                          .validatedStatus
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2932216932
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }